import { ElementType, useContext, useEffect, useRef, useState } from "react";
import DynamicFont from "react-dynamic-font";
import styled from "styled-components";
import Block, { BlockContent } from "~/components/Block";
import MediaFull from "~/components/MediaFull";
import { Desktop, Mobile } from "~/components/ShowcaseB";
import TitleRichText from "~/components/Title/TitleRichText";
import { getCommonSliceProps, getRichText, resolveMedia } from "~/external/prismic";
import { useRect } from "~/hooks/useRect";
import useTopBarColors from "~/hooks/useTopBarColors";
import connectSlice from "~/lib/connectSlice";
import { ThemeContext } from "~/lib/ThemeContext";
import { vBlockSpacing } from "~/styles/mixins";
import theme, { parseFontStyle, parseFontWeight, smallerThan } from "~/styles/theme";

const Wrapper = styled.div<any>`
  width: 100%;
  ${vBlockSpacing};
  /* remove spacing from the media */
  margin-bottom: -${(p) => p.theme.sizes.vBlockSpacing}rem;
  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    margin-bottom: -${(p) => p.theme.sizes.vBlockSpacingSmall}rem;
  }

  /* margin-bottom: -${(p) => p.theme.sizes.vBlockSpacing}rem; */
  /* padding-bottom: 200px; */
`;

const Title = styled.h1<any>`
  margin: 0;
  padding: 0;
  overflow: hidden;
  text-align: center;
  color: ${(p) => p.color ?? p.theme.colors.dark};
  line-height: 0.9;
  /* margin-bottom: ${(p) => p.theme.sizes.vBlockSpacing}rem; */
  ${(p) => smallerThan(p.theme.breakpoints.small)} {
    margin-bottom: ${(p) => p.theme.sizes.vBlockSpacingSmall / 1.5}rem;
  }
  font-size: 23vw;
  font-weight: ${(p) => p.fontWeight || "400"};
  font-family: "AIAIAI${(p) => p.fontStyle || ""}";

  > p {
    margin: 0;
    padding: 0;
    -webkit-text-stroke: 0.02em;

    font-size: ${(p) => p.size * 6.1}vw;
    color: ${(p) => p.color ?? p.theme.colors.dark};

    ${(p) =>
      !p.isRich &&
      `
    ${(p) => smallerThan(p.theme.breakpoints.small)} {
      font-size: 10vw };
    }
    `}
    position: relative;
    b,
    strong {
      font-weight: inherit;
      color: ${(p) => p.highlightColor || p.theme.colors.primary};
      ${(p) =>
        p.highlightOutline &&
        `
      color: ${(p) => p.highlightColor || p.theme.colors.primary};
      fill-color: transparent;
      -webkit-text-fill-color: transparent; /* Will override color (regardless of order) */
      -webkit-text-stroke: 6px ${(p) => p.highlightColor || p.theme.colors.primary};
      `}
    }
  }
`;

const MediaWrapperWrapper = styled.div<any>`
  background-color: ${(p) => p.backgroundColor};
  position: relative;
  padding: 0 ${(p) => p.theme.sizes.hSpacing * 2}rem;
  /*z-index: ${(p) => p.theme.z.body + 1};*/
  margin-bottom: ${(p) => p.theme.sizes.vSpacing * 2}rem;
  > * {
    transform: translateY(-${(p) => p.theme.sizes.vBlockSpacing}rem);
    ${(p) => smallerThan(p.theme.breakpoints.small)} {
      transform: translateY(-${(p) => p.theme.sizes.vBlockSpacingSmall}rem);
    }
  }
`;

function MediaWrapper(props) {
  // setup a hook so we modify the TopBar's colors when the white area of the
  // Banner is scrolled into

  const { backgroundColor, color, children } = props;

  const elem = useTopBarColors(theme.colors.light, theme.colors.dark);
  // const elem = useTopBarColors(backgroundColor, color);

  return (
    <MediaWrapperWrapper backgroundColor={backgroundColor} ref={elem}>
      {children}
    </MediaWrapperWrapper>
  );
}

type PropType = {
  spacingTop?: number;
  spacingBottom?: number;
  animateIn?: boolean;
  backgroundColor?: string;
  color?: string;
  highlightColor?: string;
  highlightOutline?: boolean;
  href?: string;
  hrefAs?: string;
  hrefTarget?: string;

  titleType?: ElementType;
  title?: string;
  titleRich?: any; // TODO: Specific type
  fontStyle?: string;
  fontWeight?: number | string;
  media?: [string, string];
  mediaMobile?: [string, string];
};

function HeroBannerB(props: PropType) {
  const currentTheme = useContext(ThemeContext);

  const {
    spacingTop = 0,
    spacingBottom = 6,

    animateIn = false,
    backgroundColor = currentTheme.background,
    color = currentTheme.foreground,
    highlightColor = currentTheme.highlight,
    highlightOutline,

    href,
    hrefAs,
    hrefTarget,

    titleType,
    title,
    titleRich,
    fontStyle = "",
    fontWeight = "400",
    media,
    mediaMobile,
  } = props;

  // console.log('HeroBannerB', props);

  const titleRef = useRef<HTMLElement>();
  // const innerTitleRef = useRef<HTMLElement>();
  const titleRect = useRect(titleRef);
  // const innerTitleRect = useRect(innerTitleRef);

  // console.log(titleRect);

  const [titleFontSize, setTitleFontSize] = useState<number>(-1);
  useEffect(() => {
    if (!titleRef.current || !window || titleFontSize > 0) return;
    /* console.log(
      'titleRef.current.clientWidth',
      titleRef.current.children[0].children[0]
    );
    console.log(
      'titleRef.current.clientWidth',
      titleRef.current.offsetWidth,
      titleRef.current.querySelector('span')?.offsetWidth,
      (titleRef.current.querySelector('span')?.offsetWidth /
        titleRef.current.offsetWidth) *
        100,
      (titleRef.current.offsetWidth /
        titleRef.current.querySelector('span')?.offsetWidth) *
        100
    ); */
    setTitleFontSize(
      (titleRef.current.offsetWidth /
        titleRef.current.querySelector("span")?.offsetWidth) *
        100
    );
  }, [titleRef]);

  // const { fontSize, ref: titleRef } = useFitText({minFontSize: 100, logLevel: 'debug'});

  // console.log(backgroundColor, color);
  // NOTE: spaceingBottom moved to the wrapper from the Block
  return (
    <Wrapper spacingBottom={spacingBottom}>
      <Block
        spacingTop={spacingTop}
        spacingBottom={13}
        backgroundColor={backgroundColor}
        color={color}
      >
        <BlockContent>
          {/* <BreadCrumbs
            color={theme.colors.light}
            text={['Headphones', 'TMA-2']}
          />
          <Divider color={theme.colors.light} /> */}

          <Title
            as={titleType}
            color={color}
            highlightColor={highlightColor}
            highlightOutline={highlightOutline}
            fontWeight={fontWeight}
            fontStyle={fontStyle}
            /* ref={titleRef}
            style={{ fontSize, width: '100%' }} */
            ref={titleRef}
            isRich={!!titleRich}
            style={titleRich && { fontSize: `${(titleRect.width / 101) * 100}%` }}
          >
            {titleRich ? (
              <TitleRichText richText={titleRich} />
            ) : (
              <DynamicFont content={title} />
            )}
            {/* false && titleRich ? (
              <TitleRichText richText={titleRich} />
            ) : (
              <span style={{whiteSpace: 'nowrap', display: 'block'}}>{title}</span>
            ) */}
          </Title>
          {/* <Spacer backgroundColor="transparent" size={2} /> */}
        </BlockContent>
      </Block>
      {media && (
        <MediaWrapper backgroundColor={backgroundColor} color={color}>
          {!mediaMobile ? (
            <MediaFull
              media={media}
              clickable={!!href}
              href={href}
              as={hrefAs}
              target={hrefTarget}
            />
          ) : (
            <>
              <Desktop>
                <MediaFull
                  media={media}
                  clickable={!!href}
                  href={href}
                  as={hrefAs}
                  target={hrefTarget}
                />
              </Desktop>
              <Mobile>
                <MediaFull
                  media={mediaMobile}
                  clickable={!!href}
                  href={href}
                  as={hrefAs}
                  target={hrefTarget}
                />
              </Mobile>
            </>
          )}
        </MediaWrapper>
      )}
    </Wrapper>
  );
}

export function mapSliceToProps(slice) {
  const { primary, items } = slice;
  const props = {
    ...getCommonSliceProps(slice),

    media: resolveMedia(primary, "16:9", "xlarge"),
    mediaMobile: resolveMedia(primary, "4:3", "large", true),
    titleType: primary.title_type,
    title: primary.title,
    titleRich: getRichText(primary.title_rich),
    fontStyle: parseFontStyle(primary.font_style, "Normal"),
    fontWeight: parseFontWeight(primary.font_weight, "Normal"),
  };

  return props;
}

export default connectSlice(mapSliceToProps)(HeroBannerB);
