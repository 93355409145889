import { useContext } from "react";
import styled from "styled-components";
import Anchor from "~/components/Anchor";
import { BlockGrid } from "~/components/Block";
import ButtonArrow from "~/components/ButtonArrow";
import LinkButton from "~/components/LinkButton";
import MediaFull from "~/components/MediaFull";
import Spacer from "~/components/Spacer";
import Title from "~/components/Title";
import { ThemeContext } from "~/lib/ThemeContext";
import { paragraph } from "~/styles/mixins";
import theme, { biggerThan, smallerThan } from "~/styles/theme";

const TextWrapper = styled.div`
  font-weight: ${(p) => p.fontWeight || "400"};
  font-family: "AIAIAI${(p) => p.fontStyle || ""}";
  color: ${(p) => p.color ?? "initial"};
  p {
    ${paragraph};
    margin: ${(p) => p.theme.sizes.vSpacing}rem 0;
    max-width: ${(p) => p.maxWidth || "none"};
  }
`;

const Img = styled.img`
  width: 100%;
  display: block;
`;

export const Mobile = styled.div`
  ${(p) => biggerThan(p.theme.breakpoints.ipadPortrait)} {
    display: none;
  }
`;

export const Desktop = styled.div`
  ${(p) => smallerThan(p.theme.breakpoints.ipadPortrait)} {
    display: none;
  }
`;

function ShowcaseB(props) {
  const currentTheme = useContext(ThemeContext);

  const {
    titleType,
    titleLine1,
    titleLine2,
    titleLine3,
    titleSize,
    titleFontStyle,
    titleFontWeight,
    highlightColor = currentTheme.highlight,
    highlightOutline,
    color = currentTheme.foreground,
    media,
    mediaLazy,
    text,
    fontStyle,
    fontWeight,
    anchorText,
    anchorId,
    anchorPreText,
    buttonText,
    href,
    hrefAs,
    hrefTarget,
    align,
    animateIn,
  } = props;

  return (
    <>
      {anchorText && (
        <Anchor id={anchorId} pre={anchorPreText} color={color}>
          {anchorText}
        </Anchor>
      )}
      <BlockGrid cols={2} oneColOn={theme.breakpoints.ipadPortrait}>
        {align === "right" && (
          <Desktop>
            {media && (
              <MediaFull
                backgroundColor={theme.colors.greyLighter}
                media={media}
                lazy={mediaLazy}
                href={href}
                as={hrefAs}
                target={hrefTarget}
                clickable
              />
            )}
          </Desktop>
        )}
        <div>
          <Title
            type={titleType}
            line1={titleLine1}
            line2={titleLine2}
            line3={titleLine3}
            titleSize={titleSize}
            fontStyle={titleFontStyle}
            fontWeight={titleFontWeight}
            highlightColor={highlightColor}
            highlightOutline={highlightOutline}
            color={color}
            size={0.7}
            animateIn={animateIn}
          />
          <Mobile>
            {media && (
              <MediaFull
                backgroundColor={theme.colors.greyLighter}
                media={media}
                lazy={mediaLazy}
                href={href}
                as={hrefAs}
                target={hrefTarget}
                clickable
              />
            )}
          </Mobile>
          {text && <TextWrapper color={color}>{text}</TextWrapper>}
          {buttonText && href && (
            <>
              <Spacer size={4} backgroundColor="transparent" />
              <LinkButton
                buttonComponent={ButtonArrow}
                buttonProps={{ color }}
                href={href}
                as={hrefAs}
                target={hrefTarget}
              >
                {buttonText}
              </LinkButton>
            </>
          )}
        </div>
        {align === "left" && (
          <Desktop>
            {media && (
              <MediaFull
                backgroundColor={theme.colors.greyLighter}
                media={media}
                lazy={mediaLazy}
                href={href}
                as={hrefAs}
                target={hrefTarget}
                clickable
              />
            )}
          </Desktop>
        )}
      </BlockGrid>
    </>
  );
}

export default ShowcaseB;
